<template>
  <div>
    <v-app>
      <v-container>
        <div class="page-card">
          
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      scrollable
      max-width="800px"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          Open Dialog
        </v-btn>
      </template>
      <v-card class="elevation-5" style='background-color:#5e5e5e !important'>
        <v-card-title>
          Kişisel Verilerin Korunması Hakkında Hekim Aydınlatma
                  Metni
                </v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 600px;">
          <v-col md="12">
              
              <p
                style="margin-top:1cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                NER Medikal Araştırma Danışmanlık&nbsp;Ltd. Şti. (<span
                  dir="RTL"
                  style=" serif;"
                  >&ldquo;</span
                ><strong>NER</strong>&rdquo;) olarak kişisel verilerinizin
                g&uuml;venliğine ve mahremiyetine olduk&ccedil;a &ouml;nem
                veriyor ve bu verilerinizi işlemek ve muhafaza etmek i&ccedil;in
                m&uuml;mk&uuml;n olan en &uuml;st seviyede g&uuml;venlik
                tedbirlerini almaya &ccedil;alışıyoruz.
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                Bu bilin&ccedil;le işbu Kişisel Verilerin Korunması Hakkında
                Hekim Aydınlatma Metni (<span dir="RTL" style=" serif;"
                  >&ldquo;</span
                ><strong>Aydınlatma Metni</strong>&rdquo;), kimliği belirli veya
                belirlenebilir ger&ccedil;ek kişiye ilişkin her t&uuml;rl&uuml;
                bilgiyi ifade eden kişisel verilerinizin 6698 Sayılı Kişisel
                Verilerin Korunması Kanunu<span dir="RTL" style=" serif;"
                  >&rsquo;</span
                >na (<span dir="RTL" style=" serif;">&ldquo;</span
                ><strong>Kanun</strong>&rdquo;) ve
                <span style="color:#1D1D1B;background:white;"
                  >hukuki dayanağını Kanun</span
                ><span dir="RTL" style=" serif;color:#1D1D1B;background:white;"
                  >&rsquo;</span
                ><span style="color:#1D1D1B;background:white;"
                  >dan alan ikincil mevzuat ile Kişisel Verileri Koruma Kurulu
                  kararlarına (Hepsi birlikte&nbsp;</span
                ><span dir="RTL" style=" serif;color:#1D1D1B;background:white;"
                  >&ldquo;</span
                ><strong
                  ><span style="color:#1D1D1B;background:white;"
                    >Veri Koruma Mevzuatı</span
                  ></strong
                ><span style="color:#1D1D1B;background:white;"
                  >&rdquo; olarak anılacaktır.) uygun olarak&nbsp;</span
                >NER&nbsp;<span style="color:#1D1D1B;background:white;"
                  >tarafından&nbsp;</span
                ><span style="background:white;"
                  >işlenme s&uuml;re&ccedil;lerine ilişkin olarak tarafınızı
                  bilgilendirmek &uuml;zere hazırlanmıştır.&nbsp;</span
                >
              </p>
              <div style="margin:0cm;font-size:16px; serif;border:none;">
                <ol style="margin-bottom:0cm;list-style-type: decimal;">
                  <li style="margin:0cm;font-size:16px; serif;border:none;">
                    <strong
                      ><span style="background:white;"
                        >Veri Sorumlusunun Kimliği</span
                      ></strong
                    ><span style="background:white;">&nbsp;</span>
                  </li>
                </ol>
              </div>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                <strong
                  ><span style="background:white;"
                    >Veri Sorumlusu Kimliğ</span
                  ></strong
                ><strong><span style="background:white;">i:</span></strong> NER
                Medikal Araştırma Danışmanlık&nbsp;Ltd. Şti.&nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;"
              >
                <strong>Adres:</strong> Esentepe Mah. B&uuml;y&uuml;kdere Cad.
                No:195 Kat:5 34394 Levent, Şişli,&nbsp;İstanbul&nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                <strong>Web Sitesi: </strong>
                <a href="http://www.ner-consulting.com" target="_blank"
                  ><span style="color:#0563C1;text-decoration:underline;"
                    ><span style="color:black;"
                      ><span style="color:black;"
                        >www.ner-consulting.com</span
                      ></span
                    ></span
                  ></a
                >&nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                <strong>Telefon Numarası:</strong> +90 212 355 89 65
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                <strong>E-Posta: </strong>
                <a href="mailto:info@ner-consulting.com"
                  ><span style="color:#0563C1;text-decoration:underline;"
                    ><span style="color:black;"
                      ><span style="color:black;"
                        >info@ner-consulting.com</span
                      ></span
                    ></span
                  ></a
                >&nbsp;
              </p>
              <br />
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <div style="margin:0cm;font-size:16px; serif;border:none;">
                <ol
                  start="2"
                  style="margin-bottom:0cm;list-style-type: undefined;"
                >
                  <li style="margin:0cm;font-size:16px; serif;border:none;">
                    <strong>İş</strong
                    ><strong>lenen Kişisel Verileriniz, Toplanma Y</strong
                    ><strong>&ouml;ntemi ve Hukuki Sebebi</strong>
                  </li>
                </ol>
              </div>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                Tarafınızdan veya &ccedil;alışmasını
                y&uuml;r&uuml;tt&uuml;ğ&uuml;n&uuml;z Dernek/Kurum/Sponsor
                tarafından aktarılan kimlik ve iletişim bilgilerinizden oluşan
                kişisel verileriniz (isim, soy isim, telefon numarası, e-posta
                adresi, &ccedil;alıştığınız kurum/hastane bilgisi, uygulama
                yeriniz, biriminiz, iş unvanınız, aktif olduğunuz tıbbi alan,
                mesleki nitelikleriniz, uzmanlık bilgileriniz ve bilimsel
                faaliyetleriniz gibi profesyonel ayrıntılar (&ouml;nceki klinik
                araştırma deneyimi ve ge&ccedil;miş veya bekleyen
                araştırma&nbsp;&ccedil;alışmalarına katılım gibi),
                MedicalResearch.Network sistemlerimizde bulunan hekim kullanıcı
                kaydı işlemleri sırasında ilgili bilgilerin doldurulması ve
                bizim ile ger&ccedil;ekleştirdiğiniz sair iletişim kapsamında;
                e-posta, telefon, diğer &ccedil;evrimi&ccedil;i ve/veya
                &ccedil;evrimdışı elektronik iletişim platformları, barındırma
                firmaları, iş birliği i&ccedil;inde olduğumuz
                dernekler/kurumlar/sponsorlar ve bunların entegre sistemleri,
                yetkili kamu kurumları ve&nbsp;&ouml;zel kuruluşlar ve bunların
                entegre sistemleri ile bunlara ileride eklenebilecek sair
                y&ouml;ntemler (kanallar) &nbsp;aracılığıyla kısmen veya tamamen
                otomatik olan yollarla elde edilmekte ve işlenmektedir.
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                Yetkili kamu kurumları tarafından talep edilmesi sebebi ile
                toplanan kişisel verilerinizi hukuki
                y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;m&uuml;z&uuml; yerine
                getirmek i&ccedil;in zorunlu olması hukuki sebebi ile,
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                Dernek/Kurum/Sponsor ile aramızda klinik
                araştırma/g&ouml;zlemsel &ccedil;alışma ve sağlık alanındaki
                &ccedil;alışmalara ilişkin s&ouml;zleşmelere dayalı bir ilişki
                olması sebebi ile toplanan kişisel verilerinizi bir
                s&ouml;zleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
                olması kaydına dayanan, s&ouml;zleşmenin taraflarına ait kişisel
                verilerin işlenmesinin gerekli olması hukuki sebebi ile,
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                MedicalResearch.Network sistemlerimizde bulunan hekim kullanıcı
                kaydı işlemleri ve tarafınızla yapacağımız sair iletişim kapsamı
                nedeni ile toplanan kişisel verilerinizi meşru menfaatimizin
                bulunması hukuki sebebi ile, işleyeceğiz.
              </p>
              <div style="margin:0cm;font-size:16px; serif;border:none;">
                <ol
                  start="3"
                  style="margin-bottom:0cm;list-style-type: undefined;"
                >
                  <li style="margin:0cm;font-size:16px; serif;border:none;">
                    <strong
                      >Kişisel Verilerinizin İşlenmesinin
                      Ama&ccedil;ları</strong
                    >
                  </li>
                </ol>
              </div>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                NER, kişisel verilerinizi aşağıda belirtilen ama&ccedil;lar ile
                işleyecektir:
              </p>
              <ul style="list-style-type: disc;">
                <li style="margin:0cm;font-size:16px; serif;border:none;">
                  Sağlık alanında araştırma yapabilmeniz (tıbbi cihaz,
                  ila&ccedil;, v.b.),
                </li>
                <li style="margin:0cm;font-size:16px; serif;border:none;">
                  Elektronik olgu rapor formu (e-ORF) yazılımının
                  kullanılabilmesi,
                </li>
                <li style="margin:0cm;font-size:16px; serif;border:none;">
                  MedicalResearch.Network sistemlerimize giriş yapabilmeniz ve
                  bu sistemleri kullanabilmeniz,
                </li>
                <li style="margin:0cm;font-size:16px; serif;border:none;">
                  Size bu kapsamda bilgilendirme yapabilmemiz,
                </li>
                <li style="margin:0cm;font-size:16px; serif;border:none;">
                  Mevzuat uyarınca ilgili kamu kurum ve kuruluşları ile edinilen
                  bilgileri paylaşma ve yanıt verme,
                </li>
                <li style="margin:0cm;font-size:16px; serif;border:none;">
                  D&uuml;zenleyici ve denetleyici kurumlarla, resmi mercilerin
                  talep ve denetimleri doğrultusunda gerekli bilgilerin temini,
                </li>
                <li style="margin:0cm;font-size:16px; serif;border:none;">
                  İlgili kişi olarak kullanacağınız haklarınıza ilişkin
                  taleplerin karşılanması.
                </li>
              </ul>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:36.0pt;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <div style="margin:0cm;font-size:16px; serif;border:none;">
                <ol
                  start="4"
                  style="margin-bottom:0cm;list-style-type: undefined;"
                >
                  <li style="margin:0cm;font-size:16px; serif;border:none;">
                    <strong
                      >Kişisel Verilerin Aktarıldığı Taraflar ve Aktarım
                      Ama&ccedil;ları&nbsp;</strong
                    >
                  </li>
                </ol>
              </div>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                NER b&uuml;nyesinde kişisel verilerinize, yalnızca yukarıda
                detaylarına yer verilen ama&ccedil;lar bakımından sınırlı yetki
                erişimine ve gizlilik y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;ne
                sahip &ccedil;alışanlarımız ve hizmet sağlayıcılarımız
                tarafından kendi g&ouml;revlerini yerine getirmek amacıyla
                gerekli&nbsp;&ouml;l&ccedil;&uuml;de erişilebilmektedir.
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                Kişisel verileriniz Veri Koruma Mevzuatı kapsamında gerekli
                raporlamaların yapılması yahut yetkili kamu kurum ve
                kuruluşların talebi halinde ilgili kişi ve kuruluşlara NER<span
                  dir="RTL"
                  style=" serif;"
                  >&rsquo;</span
                >in faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi ve denetlenmesi
                i&ccedil;in kanuni y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;m&uuml;z
                &ccedil;er&ccedil;evesinde aktarılabilecektir.
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:normal;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                Kişisel verileriniz, e-posta veri depolama hizmetlerimiz dahil
                olmak &uuml;zere kullanacağınız Medical Research.Network
                sistemlerimizin ve kimi e-posta hizmeti sunucularının yurt
                dışında bulunması sebepleriyle iş faaliyetlerimizin
                y&uuml;r&uuml;t&uuml;lmesi i&ccedil;in a&ccedil;ık rızanızın
                bulunması halinde yurt dışına aktarılabilecektir. Tarafımızla
                paylaştığınız kişisel verileriniz,&nbsp;&ouml;zellikle Microsoft
                Outlook, Gmail ve benzeri yazılımların kullanılması halinde yurt
                dışına aktarılabilir. Kişisel verileriniz, NER ile sunucu ve
                veri tabanları yurt dışında yer alan bu gibi yazılımlar yoluyla
                aktarmanız halinde kendiliğinden yurt dışına aktarılmış
                olacaktır. NER<span dir="RTL" style=" serif;">&rsquo;</span>in
                faaliyetlerinin s&uuml;rd&uuml;r&uuml;lmesi ve hukuki
                y&uuml;k&uuml;ml&uuml;l&uuml;klerinin yerine getirilmesi
                amacıyla Microsoft Outlook, Gmail, gibi sunucular ve veri
                tabanları yurt dışında yer alan yazılımları kullanılabilir,
                fakat kişisel verileriniz bu haller haricinde yurt dışına
                aktarılmaz.&nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:normal;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                Bu konuda rızanızı her zaman bu Aydınlatma Metni<span
                  dir="RTL"
                  style=" serif;"
                  >&rsquo;</span
                >nde belirtilen Veri Sorumlusu/NER iletişim adreslerinde yer
                alan adreslerden yazılı talepte bulunarak geri
                &ccedil;ekebilirsiniz.
              </p>
              <div style="margin:0cm;font-size:16px; serif;border:none;">
                <ol
                  start="5"
                  style="margin-bottom:0cm;list-style-type: undefined;"
                >
                  <li style="margin:0cm;font-size:16px; serif;border:none;">
                    <strong>Veri Saklama S&uuml;releri</strong>
                  </li>
                </ol>
              </div>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                Kişisel verileriniz, yukarıda yer alan işleme ama&ccedil;ları
                ile bağlantılı, sınırlı ve&nbsp;&ouml;l&ccedil;&uuml;l&uuml;
                olarak, ilgili mevzuat uyarınca işlemenin gerekli olduğu
                s&uuml;reler boyunca idari ve teknik tedbirler alınarak
                saklanır. Veri işleme faaliyetinin altındaki işleme amacı son
                bulan verileriniz NER tarafından Kişisel Verilerin Silinmesi,
                Yok Edilmesi veya Anonim Hale Getirilmesi Hakkında
                Y&ouml;netmelik uyarınca imha edilir.&nbsp;
              </p>
              <div style="margin:0cm;font-size:16px; serif;border:none;">
                <ol
                  start="6"
                  style="margin-bottom:0cm;list-style-type: undefined;"
                >
                  <li style="margin:0cm;font-size:16px; serif;border:none;">
                    <strong
                      ><span style="background:white;"
                        >Kişisel Verilerinize İlişkin Haklarınız</span
                      ></strong
                    ><span style="background:white;">&nbsp;</span
                    ><strong>ve Bunları Kullanma Y</strong
                    ><strong>&ouml;ntemi</strong>
                  </li>
                </ol>
              </div>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                Kişisel veri sahibi&nbsp;olarak,&nbsp;Kanun<span
                  dir="RTL"
                  style=" serif;"
                  >&rsquo;</span
                >un 11. maddesindeki&nbsp;haklarınıza ilişkin taleplerinizi
                belirtmek ve kişisel verileriniz &uuml;zerindeki haklarınızı
                kullanmak&nbsp;amacıyla&nbsp;veya vermiş olduğunuz rızanızı geri
                almak istediğinizde&nbsp;bu talebinizi yukarıdaki a&ccedil;ık
                adresimize bizzat teslim edebilir, anılmış e-postaya
                g&uuml;venli elektronik imzalı olarak veya diğer suretlerde
                kimliğinizi ve ilginizi ispatlamak yoluyla iletebilir, noter
                kanalıyla g&ouml;nderebilirsiniz. Belirlenen y&ouml;ntemlerle
                taleplerinizi iletmeniz
                durumunda,&nbsp;talebiniz&nbsp;niteliğine g&ouml;re talebi en
                kısa s&uuml;rede ve en ge&ccedil; otuz g&uuml;n i&ccedil;inde
                sonu&ccedil;landırılacaktır.&nbsp;Kanun<span
                  dir="RTL"
                  style=" serif;"
                  >&rsquo;</span
                >un 11. maddesi kapsamında aşağıdaki haklara sahipsiniz:&nbsp;
              </p>
              <ol start="1" style="list-style-type: lower-alpha;">
                <li style="margin:0cm;font-size:16px; serif;border:none;">
                  Kişisel verilerinizin işlenip işlenmediğini
                  &ouml;ğrenme,&nbsp;
                </li>
                <li style="margin:0cm;font-size:16px; serif;border:none;">
                  Kişisel verileriniz işlenmişse buna ilişkin bilgi talep
                  etme,&nbsp;
                </li>
                <li style="margin:0cm;font-size:16px; serif;border:none;">
                  Kişisel verilerinizin işlenme amacını ve bunların amacına
                  uygun kullanılıp kullanılmadığını &ouml;ğrenme,&nbsp;
                </li>
                <li style="margin:0cm;font-size:16px; serif;border:none;">
                  Yurt i&ccedil;inde veya yurt dışında kişisel verilerinizin
                  aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişileri bilme,&nbsp;
                </li>
                <li style="margin:0cm;font-size:16px; serif;border:none;">
                  Kişisel verilerinizin eksik veya yanlış işlenmiş olması
                  h&acirc;linde bunların d&uuml;zeltilmesini isteme,&nbsp;
                </li>
                <li style="margin:0cm;font-size:16px; serif;border:none;">
                  Kişisel verilerinizin silinmesini veya yok edilmesini
                  isteme,&nbsp;
                </li>
                <li style="margin:0cm;font-size:16px; serif;border:none;">
                  Kişisel verilerinizin eksik veya yanlış işlenmiş olması
                  h&acirc;linde bunların d&uuml;zeltilmesine ve/veya kişisel
                  verilerinizin silinmesini veya yok edilmesine ilişkin
                  işlemlerin kişisel verilerinizin aktarıldığı
                  &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini
                  isteme,&nbsp;
                </li>
                <li style="margin:0cm;font-size:16px; serif;border:none;">
                  İşlenen verilerinizin m&uuml;nhasıran otomatik sistemler
                  vasıtasıyla analiz edilmesi suretiyle ilgili kişi aleyhine bir
                  sonucun ortaya &ccedil;ıkmasına itiraz etme,&nbsp;
                </li>
                <li style="margin:0cm;font-size:16px; serif;border:none;">
                  Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle
                  zarara uğramanız h&acirc;linde zararın giderilmesini talep
                  etme.
                </li>
              </ol>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                Bu hakların mutlak olmadığını ve yukarıda a&ccedil;ıklanan veri
                sahibi haklarıyla ilgili olarak aşağıda belirtildiği gibi
                bir&ccedil;ok istisna, muafiyet, kısıtlama ve yeterlilikler
                bulunduğuna dikkat ediniz:
              </p>
              <ul style="list-style-type: disc;margin-left:26px;">
                <li style="margin:0cm;font-size:16px; serif;border:none;">
                  NER talebin sizin tarafınızdan veya adınıza yapıldığını
                  doğrulamak i&ccedil;in kimlik belgeleri isteyebilir;
                </li>
                <li style="margin:0cm;font-size:16px; serif;border:none;">
                  NER aşırı ve/veya a&ccedil;ık&ccedil;a mesnetsiz kabul edilen
                  bir talep doğrultusunda hareket etmeyi reddedebilir <em>(</em
                  ><em
                    >&ouml;rneğin m&uuml;kerrer taleplerde bulunulması
                    halinde</em
                  >). NER<span dir="RTL" style=" serif;">&rsquo;</span>in yine
                  de b&ouml;yle bir talebe istinaden harekete ge&ccedil;mek
                  i&ccedil;in hazırlık yapması gerekiyorsa Veri Koruma
                  Mevzuatı&rsquo;nın belirlediği sınırlar dahilinde makul bir
                  &uuml;cret yansıtabilir.
                </li>
              </ul>
              <p
                style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:36.0pt;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <p
                style="margin-top:6.0pt;margin-right:0cm;margin-bottom:6.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                Veri sahibi haklarıyla ilgili diğer belirli istisnalar ve
                sınırlandırmalar bulunmaktadır. NER, gelen talebin ardından ve
                duruma uygun olduğunu d&uuml;ş&uuml;nd&uuml;ğ&uuml; takdirde her
                t&uuml;rl&uuml; istisnayı, muafiyeti, kısıtlamayı ve yeterliliği
                sizinle g&ouml;r&uuml;şmeye gayret edecektir.&nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                <br />
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                <strong>Kişisel Verilerin Yurt Dışına Aktarım Onayı</strong>
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                Kişisel verilerimin NER tarafından iş faaliyetlerinin
                y&uuml;r&uuml;t&uuml;lmesi ve denetlenmesi i&ccedil;in gerekli
                olması nedeniyle kullanmakta olduğu global veri işleme
                sistemlerine, hizmetlerinden faydalanılan yurt dışında yerleşik
                yahut veri tabanları yurt dışında bulunan hizmet
                sağlayıcılarının veya bulut hizmet sağlayıcılarının veri
                tabanlarına<em
                  >&nbsp;(Microsoft Outlook, Gmail, e-ORF, Hosting ve
                  benzeri</em
                >) kaydedilmek ve işlenmek &uuml;zere yurt dışına aktarılmasını
                kabul ediyorum. A&ccedil;ık rızamı her zaman geri
                &ccedil;ekebileceğimi ve bu ama&ccedil;la NER ile iletişime
                ge&ccedil;ebileceğimi anladım.
              </p>
              <p
                style="margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:0cm;line-height:107%;font-size:15px; sans-serif;color:black;border:none;text-align:justify;"
              >
                &nbsp;
              </p>
            </v-col>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
          >
            KAPAT
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
        </div>
      </v-container>
    </v-app>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
//@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>
//import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
//import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
//import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
//import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

//import KTUtil from "@/assets/js/components/util";
//import { mapGetters, mapState } from "vuex";
//import { LOGIN, LOGOUT, REGISTER } from "@/core/services/store/auth.module";
//import Swal from "sweetalert2";
//import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
export default {
  name: "kvkklogin",
  data() {
    return {};
  },
  computed: {},
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "KVKK", route: "/kvkklogin" },
    ]);
  },
  methods: {},
};
</script>
